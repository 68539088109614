import { gql, useMutation } from '@apollo/client';
import { AppHeader_Logout } from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import { NavigationComponentLocators } from '@app/e2e/screens/Navigation';
import { useAuthNavigation } from '@app/navigations';
import { ITrustCapitalLogo, ResetPassword } from '@app/shared/components';
import { useHover, useOnClickOutside } from '@app/shared/hooks';
import { AppIcon, AppIconType, useAppState, useAppTheme } from '@itrustcapital/ui';
import { List, ListItem, Text } from '@ui-kitten/components';
import React, { useRef } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

export interface AppHeaderOptionProps {
	name: string;
	onPress: () => void;
	icon: {
		lib: AppIconType;
		name: string;
	};
	testID: string;
}

const CustomListItem = (props: AppHeaderOptionProps) => {
	const styles = useCustomStyles();
	const theme = useAppTheme();
	const { isHovered, hoverRef } = useHover<View>();

	return (
		<View ref={hoverRef}>
			<ListItem
				style={[styles.action.item, isHovered && styles.action.hover]}
				testID={props.testID}
				onPress={props.onPress}
			>
				{isHovered && <View style={styles.action.hoverBar} />}
				<View style={styles.action.content}>
					<AppIcon
						color={isHovered ? theme['color-primary-500'] : undefined}
						lib={props.icon.lib}
						name={props.icon.name}
						size={16}
						style={styles.action.icon}
					/>
					<Text style={[styles.action.text, isHovered && styles.action.textHovered]}>
						{props.name}
					</Text>
				</View>
			</ListItem>
		</View>
	);
};

const APP_HEADER_LOGOUT = gql`
	mutation AppHeader_Logout {
		logout {
			success
			errorMessage
		}
	}
`;

export function AppHeader() {
	const styles = useCustomStyles();
	const toolTip = useAppState(false);
	const resetPasswordVisible = useAppState(false);
	const ref = useRef(null);
	const maestroUserAtom = useMaestroUserAtom();
	const { SignOut: logoutAuth0 } = useAuthNavigation();

	const [logoutGraphQl] = useMutation<AppHeader_Logout>(APP_HEADER_LOGOUT);

	useOnClickOutside(ref, () => toolTip.set(false));

	const logout = async () => {
		await logoutGraphQl();
		logoutAuth0();
	};

	const listOptions: AppHeaderOptionProps[] = [
		{
			name: 'Logout',
			onPress: logout,
			icon: { lib: 'mat', name: 'logout' },
			testID: NavigationComponentLocators.logoutButton,
		},
		{
			name: 'Change Password',
			onPress: () => {
				toolTip.set(false);
				resetPasswordVisible.set(true);
			},
			icon: { lib: 'mat', name: 'lock-open' },
			testID: NavigationComponentLocators.changePasswordButton,
		},
	];

	return (
		<View style={styles.main.container}>
			<ITrustCapitalLogo height={30} />
			<TouchableOpacity onPress={() => toolTip.set(true)}>
				<View style={styles.profile.container}>
					<Text category="s1">{maestroUserAtom.user?.firstName!}</Text>
					<View style={styles.profile.abrvContainer}>
						<Text style={styles.profile.text}>
							{maestroUserAtom.user?.firstName?.[0]}
						</Text>
					</View>
				</View>
			</TouchableOpacity>
			<View ref={ref} style={toolTip.get ? styles.main.tooltip : styles.main.hidden}>
				<View>
					<View style={styles.main.arrow} />
					<List
						data={listOptions}
						renderItem={(data) => (
							<CustomListItem
								icon={data.item.icon}
								key={data.item.name}
								name={data.item.name}
								testID={data.item.testID}
								onPress={data.item.onPress}
							/>
						)}
					/>
				</View>
			</View>
			<ResetPassword
				visible={resetPasswordVisible.get}
				onClose={() => resetPasswordVisible.set(false)}
			/>
		</View>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return {
		main: StyleSheet.create({
			container: {
				borderBottomColor: theme['border-basic-color-3'],
				borderBottomWidth: 1,
				paddingHorizontal: 12,
				paddingVertical: 12,
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
			},
			tooltip: {
				backgroundColor: theme['background-basic-color-1'],
				borderColor: theme['background-basic-color-1'],
				borderRadius: 0,
				maxWidth: 200,
				maxHeight: 256,
				position: 'absolute',
				top: 55,
				right: 10,
			},
			arrow: {
				position: 'absolute',
				backgroundColor: theme['background-basic-color-1'],
				height: 20,
				width: 20,
				transform: [{ rotate: '45deg' }],
				top: 0,
				right: 15,
			},
			hidden: {
				display: 'none',
			},
		}),
		profile: StyleSheet.create({
			container: {
				flexDirection: 'row',
				alignItems: 'center',
			},
			abrvContainer: {
				width: 50,
				height: 35,
				borderRadius: 5,
				backgroundColor: '#C9F7F5',
				marginLeft: 12,
				alignItems: 'center',
				justifyContent: 'center',
			},
			text: {
				textTransform: 'capitalize',
			},
		}),
		action: StyleSheet.create({
			item: {
				flex: 1,
				width: 200,
				position: 'relative',
			},
			content: {
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
			},
			text: {
				marginLeft: 10,
			},
			icon: {
				marginLeft: 20,
			},
			hover: {
				backgroundColor: theme['color-primary-transparent-100'],
			},
			hoverBar: {
				width: 4,
				height: '100%',
				backgroundColor: theme['color-primary-500'],
				position: 'absolute',
				top: 0,
				left: 4,
			},
			textHovered: {
				color: theme['color-primary-500'],
			},
		}),
	};
}
