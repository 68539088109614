import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
	FundingScreen_UpdateFundingCancel,
	FundingScreen_UpdateFundingCancelVariables,
	FundingsScreen_GetFundings,
	FundingsScreen_GetFundingsVariables,
} from '@app/codegen';

// Get Fundings
export const FUNDING_SCREEN_GET_FUNDINGS = gql`
	query FundingsScreen_GetFundings(
		$where: FundingFilterInput
		$order: [FundingSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
		$input: GetFundingsInput
	) {
		fundings(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
			input: $input
		) {
			nodes {
				id
				type
				rolloverType
				createdAt
				fundedAt
				stage
				documentFileUploadedDate
				toAccount {
					user {
						firstName
						lastName
					}
					accountNumber
					status
				}
				fromAccount {
					user {
						firstName
						lastName
					}
					accountNumber
					status
				}
				fundingAssets {
					currencyType
					amount
					requestedAmount
					status
				}
				documents {
					files {
						id
						createdAt
						document {
							fileTemplate
						}
					}
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export type GetFundingsQueryProps = QueryHookOptions<
	FundingsScreen_GetFundings,
	FundingsScreen_GetFundingsVariables
>;

export function useFundingsScreen_GetFundingsQuery(props: GetFundingsQueryProps) {
	return useQuery<FundingsScreen_GetFundings, FundingsScreen_GetFundingsVariables>(
		FUNDING_SCREEN_GET_FUNDINGS,
		{
			...props,
			fetchPolicy: 'no-cache',
		}
	);
}

// Funding Cancel
export const FUNDING_SCREEN_UPDATE_FUNDING_CANCEL = gql`
	mutation FundingScreen_UpdateFundingCancel($input: CancelFundingInput!) {
		cancelFunding(input: $input) {
			success
			errorMessage
		}
	}
`;

export function useFundingsScreenCancelFundingMutation() {
	return useMutation<
		FundingScreen_UpdateFundingCancel,
		FundingScreen_UpdateFundingCancelVariables
	>(FUNDING_SCREEN_UPDATE_FUNDING_CANCEL);
}
