import {
	FundingType,
	FundingStage,
	AccountStatus,
	AccountStage,
	CurrencyType,
	RolloverType,
	FundingStatus,
} from '@app/codegen';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export enum CustomAccountStatus {
	INTERNAL_FUNDED = 'INTERNAL - FUNDED',
}

export type CustomAccountStatusType = AccountStatus | CustomAccountStatus;

export const FundingScreenFilterSchema = yup
	.object({
		search: yup.string().default(''),
		minAmount: yup.string().default(''),
		maxAmount: yup.string().default(''),
		type: yup.mixed<FundingType>().oneOf(Object.values(FundingType)).default(undefined),
		rolloverType: yup
			.mixed<RolloverType | 'All'>()
			.oneOf([
				'All',
				RolloverType.CARES_ACT_ROLLOVER,
				RolloverType._60_DAY_ROLLOVER,
				RolloverType.NONE,
			])
			.default(undefined),
		stage: yup.mixed<FundingStage>().oneOf(Object.values(FundingStage)).default(undefined),
		accountStatus: yup
			.mixed<CustomAccountStatusType>()
			.oneOf(Object.values(AccountStatus))
			.oneOf([
				CustomAccountStatus.INTERNAL_FUNDED,
				AccountStatus.ACTIVE,
				AccountStatus.CLOSED,
				AccountStatus.DISABLED,
				AccountStatus.INTERNAL,
				AccountStatus.NONE,
				AccountStatus.PENDING,
			])
			.default(undefined),
		accountStage: yup
			.mixed<AccountStage>()
			.oneOf(Object.values(AccountStage))
			.default(undefined),
		assetType: yup.mixed<CurrencyType>().oneOf(Object.values(CurrencyType)).default(undefined),
		createdFrom: yup.date().default(undefined),
		createdTo: yup.date().default(undefined),
		fundedFrom: yup.date().default(undefined),
		fundedTo: yup.date().default(undefined),
		fundingStatus: yup
			.mixed<FundingStatus>()
			.oneOf(Object.values(FundingStatus))
			.default(undefined),
	})
	.defined();

export type FilterFundingsForm = yup.TypeOf<typeof FundingScreenFilterSchema>;

export function useFundingsScreenFilterForm() {
	return useForm<FilterFundingsForm>({
		mode: 'all',
		resolver: yupResolver(FundingScreenFilterSchema),
		defaultValues: FundingScreenFilterSchema.cast({}),
	});
}
