import {
	AccountStage,
	AccountType,
	CurrencyType,
	DocuSignTemplateType,
	EmailTemplateType,
	FileTemplateType,
	RolloverType,
	StakingMovementDirection,
	PaymentType,
} from '@app/codegen';
import { humanize } from '@itrustcapital/ui';

export function enumToUrl(enumValue: string | null | undefined): string {
	return enumValue?.toLowerCase().replace(/_/g, '-') || '';
}

export function urlToEnum(urlValue: string | null | undefined): string {
	return urlValue?.toUpperCase().replace(/-/g, '_') || '';
}

export function enumKeyHumanizer(enumKey: string): string {
	return enumKey
		.replace(/([A-Z])/g, ' $1')
		.replace(/_/g, ' ')
		.trim();
}

/** Humanizers */
export function accountTypeHumanizer(enumValue: AccountType): string {
	switch (enumValue) {
		case AccountType.TRADITIONAL_IRA:
			return 'Traditional IRA';
		case AccountType.ROTH_IRA:
			return 'Roth IRA';
		case AccountType.SEP_IRA:
			return 'SEP IRA';
		case AccountType.SIMPLE_IRA:
			return 'Simple IRA';
		case AccountType.INHERITED_TRADITIONAL_IRA:
			return 'Inherited Traditional IRA';
		case AccountType.INHERITED_ROTH_IRA:
			return 'Inherited Roth IRA';
		case AccountType.TRADITIONAL401_K:
			return '401k Traditional';
		case AccountType.ROTH401_K:
			return '401k Roth';
		case AccountType.ROTH_TSP:
			return 'Roth TSP';
		case AccountType.TRADITIONAL_TSP:
			return 'Traditional TSP';
		case AccountType.TRADITIONAL403_B:
			return '403b Traditional';
		case AccountType.ROTH403_B:
			return '403b Roth';
		case AccountType.ROTH457:
			return '457 Roth';
		case AccountType.TRADITIONAL457:
			return '457 Traditional';
		case AccountType.TPA401_K:
			return 'TPA 401k';
		case AccountType.TPA_TRADITIONAL_IRA:
			return 'TPA Traditional IRA';
		case AccountType.TPA_SEP_IRA:
			return 'TPA SEP IRA';
		case AccountType.TPA_ROTH_IRA:
			return 'TPA Roth IRA';
		case AccountType.TPA_NTA:
			return 'TPA Personal Account';
		default:
			return humanize(enumValue);
	}
}

export function rolloverTypeHumanizer(enumValue: RolloverType): string {
	switch (enumValue) {
		case RolloverType._60_DAY_ROLLOVER:
			return '60 Day Rollover';
		default:
			return humanize(enumValue);
	}
}

export function documentTemplateTypeHumanizer(
	enumValue: FileTemplateType | DocuSignTemplateType
): string {
	switch (enumValue) {
		case FileTemplateType.GOVERNMENT_ID:
			return 'Government ID';
		case FileTemplateType.SSN_CARD:
			return 'SSN Card';
		case FileTemplateType.INHERITED_IRA_TRANSFER_FORM:
			return 'Inherited IRA Transfer Form';
		case DocuSignTemplateType.IRA_ADOPTION_AGREEMENT:
			return 'IRA Adoption Agreement';
		default:
			return humanize(enumValue);
	}
}

export function emailTemplateTypeHumanizer(enumValue: EmailTemplateType): string {
	switch (enumValue) {
		case EmailTemplateType.ITC_FUNDING_CONTRIBUTION:
			return 'ITC Funding Contribution';
		case EmailTemplateType.ITC_FUNDING_ROLLOVER:
			return 'ITC Funding Rollover';
		case EmailTemplateType.ITC_FUNDING_TRANSFER:
			return 'ITC Funding Transfer';
		case EmailTemplateType.ITC_SSN_VERIFICATION:
			return 'ITC SSN Verification';
		case EmailTemplateType.ITC_TRANSFER_RECEIPT:
			return 'ITC Transfer Receipt';
		default:
			return humanize(enumValue);
	}
}

export function getCurrencyType(): string[] {
	const currencyType = Object.values(CurrencyType);

	const USDIndex = currencyType.findIndex((x) => x === CurrencyType.USD);
	const USD = currencyType[USDIndex];
	currencyType.splice(USDIndex, 1);

	const BTCIndex = currencyType.findIndex((x) => x === CurrencyType.BTC);
	const BTC = currencyType[BTCIndex];
	currencyType.splice(BTCIndex, 1);

	const ETHIndex = currencyType.findIndex((x) => x === CurrencyType.ETH);
	const ETH = currencyType[ETHIndex];
	currencyType.splice(ETHIndex, 1);

	return [USD, BTC, ETH, ...currencyType];
}

export function accountStageTypeHumanizer(enumValue: AccountStage): string {
	switch (enumValue) {
		case AccountStage.ONBOARDING_PERSONAL_INFORMATION:
		case AccountStage.ONBOARDING_CONTACT_INFORMATION:
		case AccountStage.ONBOARDING_GOVERNMENT_ID:
		case AccountStage.ONBOARDING_ACCOUNT_TYPE:
		case AccountStage.ONBOARDING_FUNDING_TYPE:
		case AccountStage.ONBOARDING_FUNDING:
		case AccountStage.ONBOARDING_BENEFICIARIES:
			return humanize(enumValue).replace('Onboarding ', '');
		default:
			return humanize(enumValue);
	}
}

export function StakingMovementDirectionHumanizer(enumValue: StakingMovementDirection): string {
	switch (enumValue) {
		case StakingMovementDirection.OUTBOUND:
			return 'Stake';
		case StakingMovementDirection.INBOUND:
			return 'Unstake';
		default:
			return 'None';
	}
}

export function getTerms(): string[] {
	return ['90', '180', '270', '365'];
}

export function paymentTypeHumanizer(type: PaymentType): string {
	switch (type) {
		case PaymentType.ACH:
		case PaymentType.ACH_DEPOSIT:
		case PaymentType.ACH_WITHDRAWAL:
		case PaymentType.INCOMING_ACH_DEPOSIT:
		case PaymentType.INCOMING_ACH_WITHDRAWAL:
		case PaymentType.NTA_ACH_DEPOSIT:
		case PaymentType.NTA_ACH_WITHDRAWAL:
			return 'ACH';
		case null:
			return '';
		default:
			return humanize(type);
	}
}
