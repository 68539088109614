import {
	FundingStage,
	FundingStatus,
	AccountStage,
	AccountStatus,
	FundingType,
	CurrencyType,
	RolloverType,
} from '@app/codegen';
import { FundingsScreenLocators } from '@app/e2e/screens/Fundings';
import { Accordion } from '@app/shared/components';
import {
	AppIcon,
	AppSelector,
	humanize,
	maskCurrency,
	dateTimePlaceholder,
	datePickerMinDate,
	datePickerMaxDate,
	datePickerService,
	useAppDevice,
	ScreenSizeEnum,
	useAppState,
	capitalizeWords,
} from '@itrustcapital/ui';
import { Button, Datepicker, Input } from '@ui-kitten/components';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { CustomAccountStatus, CustomAccountStatusType } from '../FundingsScreenForms';
import { useFundingsScreenContext } from '../FundingsScreenProvider';

interface FilterFundingsFilterFormProps {
	isFundingsInflow: boolean;
}

const getAccountStatusFilters = (isFundingsInflow: boolean) => {
	const filters: CustomAccountStatusType[] = [...Object.values(AccountStatus)];

	if (isFundingsInflow) {
		filters.push(CustomAccountStatus.INTERNAL_FUNDED);
	}

	return filters.sort();
};

export function FilterFundingsFilterForm(props: FilterFundingsFilterFormProps) {
	const styles = useCustomStyles();
	const state = useFundingsScreenContext();

	const isRollover = useAppState<boolean>(false);
	const fundingType = state.filterForm.watch().type;

	useEffect(() => {
		if (state.filterForm.getValues().type === FundingType.ROLLOVER) {
			isRollover.set(true);
		} else {
			isRollover.set(false);
		}
	}, [fundingType]);

	return (
		<>
			<View style={styles.main.row}>
				<Controller
					control={state.filterForm.control}
					name="search"
					render={(control) => (
						<Input
							accessoryRight={() => (
								<TouchableOpacity
									testID={FundingsScreenLocators.fundingsSearchIconButton}
									onPress={state.filterForm.handleSubmit(state.onSubmit)}
								>
									<AppIcon lib="fa5" name="search" size={18} />
								</TouchableOpacity>
							)}
							placeholder="Search for fundings by customer name, email, or account number"
							style={styles.main.search}
							testID={FundingsScreenLocators.fundingsSearchField}
							value={control.field.value!}
							onBlur={control.field.onBlur}
							onChangeText={control.field.onChange}
							onSubmitEditing={state.filterForm.handleSubmit(state.onSubmit)}
						/>
					)}
				/>
				<Controller
					control={state.filterForm.control}
					name="stage"
					render={(control) => (
						<View style={styles.main.fundingStage}>
							<AppSelector
								closeOnSelect
								data={Object.values(FundingStage)}
								itemDisplay={humanize}
								placeholder="Funding Stage"
								testID={FundingsScreenLocators.fundingsStageField}
								value={control.field.value}
								onSelect={(data) => {
									control.field.onChange(data);
									state.filterForm.handleSubmit(state.onSubmit)();
								}}
							/>
						</View>
					)}
				/>
			</View>
			<Accordion
				label="advanced filters"
				triggerContainerStyle={styles.advanced.triggerContainer}
			>
				<View style={styles.advanced.inputRow}>
					<Controller
						control={state.filterForm.control}
						name="minAmount"
						render={(control) => (
							<Input
								label="Min Amount"
								placeholder=""
								style={styles.advanced.control}
								testID={FundingsScreenLocators.fundingsMinAmountField}
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChangeText={(text) => {
									const formatted = maskCurrency(text);
									control.field.onChange(formatted);
								}}
							/>
						)}
					/>
					<Controller
						control={state.filterForm.control}
						name="maxAmount"
						render={(control) => (
							<Input
								label="Max Amount"
								placeholder=""
								style={styles.advanced.control}
								testID={FundingsScreenLocators.fundingsMaxAmountField}
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChangeText={(text) => {
									const formatted = maskCurrency(text);
									control.field.onChange(formatted);
								}}
							/>
						)}
					/>
					<Controller
						control={state.filterForm.control}
						name="fundingStatus"
						render={(control) => (
							// FILTER BY FUNDING STATUS
							<View style={styles.advanced.control}>
								<AppSelector
									closeOnSelect
									data={[
										FundingStatus.PENDING,
										FundingStatus.IN_REVIEW,
										FundingStatus.APPROVED,
										FundingStatus.DECLINED,
										FundingStatus.CANCELED,
										FundingStatus.APPROVED_THEN_DECLINED,
									]}
									itemDisplay={humanize}
									label="Funding Status"
									value={control.field.value}
									onSelect={(value) => {
										control.field.onChange(value || undefined);
									}}
								/>
							</View>
						)}
					/>
				</View>
				<View style={styles.advanced.inputRow}>
					<Controller
						control={state.filterForm.control}
						name="createdFrom"
						render={(control) => (
							<Datepicker
								date={control.field.value}
								dateService={datePickerService}
								label="Created From"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
								status={control.fieldState.error && 'danger'}
								style={styles.advanced.control}
								testID={FundingsScreenLocators.fundingsCreatedFromField}
								onBlur={control.field.onBlur}
								onSelect={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={state.filterForm.control}
						name="createdTo"
						render={(control) => (
							<Datepicker
								date={control.field.value}
								dateService={datePickerService}
								label="Created To"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
								status={control.fieldState.error && 'danger'}
								style={styles.advanced.control}
								testID={FundingsScreenLocators.fundingsCreatedToField}
								onBlur={control.field.onBlur}
								onSelect={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={state.filterForm.control}
						name="fundedFrom"
						render={(control) => (
							<Datepicker
								date={control.field.value}
								dateService={datePickerService}
								label="Funded From"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
								status={control.fieldState.error && 'danger'}
								style={styles.advanced.control}
								onBlur={control.field.onBlur}
								onSelect={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={state.filterForm.control}
						name="fundedTo"
						render={(control) => (
							<Datepicker
								date={control.field.value}
								dateService={datePickerService}
								label="Funded To"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
								status={control.fieldState.error && 'danger'}
								style={styles.advanced.control}
								onBlur={control.field.onBlur}
								onSelect={control.field.onChange}
							/>
						)}
					/>
				</View>
				<View style={styles.advanced.inputRow}>
					<Controller
						control={state.filterForm.control}
						name="accountStage"
						render={(control) => (
							<View style={styles.advanced.control}>
								<AppSelector
									closeOnSelect
									data={Object.values(AccountStage).filter(
										(x) => x !== AccountStage.NONE
									)}
									itemDisplay={humanize}
									label="Account Stage"
									value={control.field.value}
									onSelect={(value) => {
										control.field.onChange(value || undefined);
									}}
								/>
							</View>
						)}
					/>
					<Controller
						control={state.filterForm.control}
						name="accountStatus"
						render={(control) => (
							<View style={styles.advanced.control}>
								<AppSelector
									closeOnSelect
									data={getAccountStatusFilters(props.isFundingsInflow)}
									itemDisplay={capitalizeWords}
									label="Account Status"
									testID={FundingsScreenLocators.fundingsAccountStatusField}
									value={control.field.value}
									onSelect={(value) => {
										control.field.onChange(value || undefined);
									}}
								/>
							</View>
						)}
					/>

					<Controller
						control={state.filterForm.control}
						name="type"
						render={(control) => (
							<View style={styles.advanced.control}>
								<AppSelector
									closeOnSelect
									data={Object.values(FundingType)}
									itemDisplay={humanize}
									label="Funding Type"
									testID={FundingsScreenLocators.fundingsTypeField}
									value={control.field.value}
									onSelect={(value) => {
										control.field.onChange(value || undefined);
									}}
								/>
							</View>
						)}
					/>

					{isRollover.get && (
						<Controller
							control={state.filterForm.control}
							name="rolloverType"
							render={(control) => (
								<View style={styles.advanced.control}>
									<AppSelector
										closeOnSelect
										data={[
											'All',
											RolloverType.CARES_ACT_ROLLOVER,
											RolloverType._60_DAY_ROLLOVER,
											RolloverType.NONE,
										]}
										itemDisplay={humanize}
										label="Rollover Type"
										value={control.field.value}
										onSelect={(value) => {
											control.field.onChange(value || undefined);
										}}
									/>
								</View>
							)}
						/>
					)}

					<Controller
						control={state.filterForm.control}
						name="assetType"
						render={(control) => (
							<View style={styles.advanced.control}>
								<AppSelector
									closeOnSelect
									data={Object.values(CurrencyType)}
									label="Asset Type"
									value={control.field.value}
									onSelect={(value) => {
										control.field.onChange(value || undefined);
									}}
								/>
							</View>
						)}
					/>
				</View>
				<View style={[styles.main.row, styles.main.buttons]}>
					<Button
						style={styles.advanced.apply}
						testID={FundingsScreenLocators.fundingsApplyFilterButton}
						onPress={state.filterForm.handleSubmit(state.onSubmit)}
					>
						Apply Filters
					</Button>
					<Button
						appearance="outline"
						testID={FundingsScreenLocators.fundingsClearFilterButton}
						onPress={() => {
							state.filterForm.reset();
							state.filterForm.handleSubmit(state.onSubmit)();
						}}
					>
						Clear Filters
					</Button>
				</View>
			</Accordion>
		</>
	);
}

function useCustomStyles() {
	const { width } = useAppDevice();

	const isScreenMd = width >= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			buttons: {
				paddingVertical: 4,
			},
			row: {
				flexDirection: 'row',
				alignItems: 'center',
			},
			inputHalves: {
				flex: 0.49,
			},
			searchRow: {
				flexDirection: isScreenMd ? 'row' : 'column',
				justifyContent: isScreenMd ? 'space-between' : 'center',
			},
			search: {
				flex: 1,
				marginRight: 8,
			},
			fundingStage: {
				flex: 1,
			},
		}),
		advanced: StyleSheet.create({
			apply: {
				marginRight: 12,
			},
			control: {
				width: isScreenMd ? '35%' : 'auto',
				maxWidth: 280,
				marginRight: isScreenMd ? 8 : 0,
				paddingVertical: 8,
			},
			inputRow: {
				flexWrap: 'wrap',
				flexDirection: isScreenMd ? 'row' : 'column',
				justifyContent: isScreenMd ? 'flex-start' : 'center',
			},
			triggerContainer: {
				flexDirection: 'row',
				justifyContent: 'flex-start',
				marginVertical: 8,
			},
		}),
	};
}
