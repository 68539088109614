import { FundingStatus } from '@app/codegen';
import { humanize } from '@itrustcapital/ui';
import { Text } from '@ui-kitten/components';
import React, { ReactNode } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

export interface FundingStatusBubbleProps {
	style?: StyleProp<ViewStyle>;
	children?: ReactNode;
	title?: string;
	status?: FundingStatus | null;
}

export function FundingStatusBubble(props: FundingStatusBubbleProps) {
	const styles = useCustomStyles();

	function renderStatus(): JSX.Element | undefined {
		let statusBackground = '';
		let statusTextColor = '';

		switch (props.status) {
			case FundingStatus.APPROVED:
				statusBackground = '#EBF4EC';
				statusTextColor = '#2D7738';
				break;
			case FundingStatus.IN_REVIEW:
				statusBackground = '#E8F4FD';
				statusTextColor = '#005AA3';
				break;
			case FundingStatus.PENDING:
				statusBackground = '#FDF0E3';
				statusTextColor = '#AE5700';
				break;
			case FundingStatus.DECLINED:
			case FundingStatus.APPROVED_THEN_DECLINED:
				statusBackground = '#FAEAEA';
				statusTextColor = '#970C0C';
				break;
			case FundingStatus.CANCELED:
				statusBackground = '#F5F7F9';
				statusTextColor = '#252A31';
				break;
		}

		return (
			<View style={[styles.main.status, { backgroundColor: statusBackground }]}>
				<Text category="c2" style={{ color: statusTextColor }}>
					{humanize(props.status!)}
				</Text>
			</View>
		);
	}

	return (
		<View style={styles.main.container}>
			{props.title && <Text category="p2">{props.title}: </Text>}
			{renderStatus()}
		</View>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
			},
			status: {
				marginLeft: 8,
				paddingVertical: 4,
				paddingHorizontal: 8,
				borderRadius: 12,
				maxWidth: 180,
			},
		}),
	};
}
