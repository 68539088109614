import { gql, useMutation } from '@apollo/client';
import {
	TradeCreditModal_CreditTradeFees,
	TradeCreditModal_CreditTradeFeesVariables,
} from '@app/codegen';
import { EAS_GET_PORTFOLIO } from '@app/screens/EditAccount/Portfolio/Portfolio';
import { CustomCard } from '@app/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppModal, useAppDevice } from '@itrustcapital/ui';
import { Button, Input, Spinner, Text } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';
import * as yup from 'yup';

export interface TradeCreditModalProps {
	accountId: number;
	toggleVisible: () => void;
	onSuccess?: (amount: number) => void;
	onError?: () => void;
	visible: boolean;
}

export const TRADE_CREDIT_MODAL_CREDIT_TRADE_FEES = gql`
	mutation TradeCreditModal_CreditTradeFees($input: CreditTradeFeesInput!) {
		creditTradeFees(input: $input) {
			success
			errorMessage
		}
	}
`;

const schema = yup
	.object({
		amount: yup.string().default('').required(),
		reason: yup.string().default(''),
	})
	.defined();

export type CreditFormData = yup.TypeOf<typeof schema>;

export function TradeCreditModal(props: TradeCreditModalProps) {
	const styles = useCustomStyles();
	const form = useForm<CreditFormData>({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});

	const [creditTradeFees, creditTradeFeesMutation] = useMutation<
		TradeCreditModal_CreditTradeFees,
		TradeCreditModal_CreditTradeFeesVariables
	>(TRADE_CREDIT_MODAL_CREDIT_TRADE_FEES, {
		refetchQueries: [EAS_GET_PORTFOLIO],
	});

	async function onSubmit(data: CreditFormData): Promise<void> {
		const amount = /^\d+(\.\d+)?$/.test(data.amount) ? +data.amount : 0;
		const amountToCredit = +(amount * 0.01).toFixed(2);

		try {
			await creditTradeFees({
				variables: {
					input: {
						accountIds: [props.accountId],
						amount: amountToCredit,
						reason: data.reason,
					},
				},
			});
			form.reset();

			if (props?.onSuccess) {
				props.onSuccess(amount);
			}
		} catch (error) {
			if (props?.onError) {
				props.onError();
			}
		}
	}

	function onCloseModal(): void {
		form.reset();
		props.toggleVisible();
	}

	return (
		<AppModal visible={props.visible} onBackdropPress={props.toggleVisible}>
			<CustomCard
				hasCloseButton
				footer={() => (
					<View style={styles.actions.container}>
						<Button
							appearance="outline"
							testID={'credit-modal-cancel-btn'}
							onPress={onCloseModal}
						>
							Cancel
						</Button>

						<Button
							disabled={
								!form.formState.isDirty ||
								!form.formState.isValid ||
								creditTradeFeesMutation.loading
							}
							style={styles.actions.add}
							testID={'credit-modal-add-btn'}
							onPress={form.handleSubmit(onSubmit)}
						>
							{creditTradeFeesMutation.loading ? (
								<View>
									<Spinner size="tiny" status="basic" />
								</View>
							) : (
								'Add'
							)}
						</Button>
					</View>
				)}
				handleClosePress={onCloseModal}
				header={'Crediting Account'}
				style={styles.main.container}
			>
				<View style={styles.inputs.row}>
					<Text style={styles.inputs.label}>
						Amount:<Text status="danger">*</Text>
					</Text>
					<Controller
						control={form.control}
						name="amount"
						render={(control) => (
							<Input
								placeholder="Enter amount"
								style={styles.inputs.control}
								testID={'credit-modal-amount-input'}
								value={control.field.value!}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
				</View>

				<Controller
					control={form.control}
					name="reason"
					render={(control) => (
						<Input
							multiline
							numberOfLines={6}
							placeholder="Reason for crediting"
							testID={'credit-modal-reason-input'}
							value={control.field.value!}
							onBlur={control.field.onBlur}
							onChangeText={control.field.onChange}
						/>
					)}
				/>
			</CustomCard>
		</AppModal>
	);
}

function useCustomStyles() {
	const appDevice = useAppDevice();

	return {
		main: StyleSheet.create({
			container: {
				minWidth: appDevice.isNativeMobile ? 'auto' : 600,
			},
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
		}),
		inputs: StyleSheet.create({
			row: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: 8,
			},
			label: {
				flex: 0.3,
			},
			control: {
				flex: 0.7,
			},
		}),
		actions: StyleSheet.create({
			container: {
				justifyContent: 'flex-end',
				flexDirection: 'row',
			},
			add: {
				marginLeft: 8,
			},
		}),
	};
}
